<template>
  <Section
    title="Player Configuration"
    id="configForm"
    ref="configForm"
    @scroll="handleScroll"
  >
    <form @submit.prevent="onSubmit">
      <SectionItem title="Sources">
        <div class="sources-container">
          <FormSelect
            v-model="playerOptions.sources.type"
            :data="['application/x-mpegURL']"
            title="Type"
            helpText="ประเภทของวิดีโอ"
            placeholder="Select a video's type"
            class="span-1"
            required
            :onBlur="(e) => validateInput(e.target.value, 'type')"
            :isShowErrorMessage="errors.type"
            errorMessage="Please put Type"
          />
          <FormInput
            v-model="playerOptions.sources.src"
            title="URL"
            helpText="URL ของวิดีโอ"
            placeholder="Video's URL"
            class="span-3-end"
            required
            :onBlur="(e) => validateInput(e.target.value, 'src')"
            :isShowErrorMessage="errors.src"
            errorMessage="Please put URL"
          />
          <FormInput
            v-model="playerOptions.sources.title"
            title="Title"
            helpText="ชื่อของวิดีโอที่จะนำมาแสดงบน video player"
            guideText="*Required เมื่อเปิดใช้งาน ByteArk Lighthouse"
            placeholder="Video title"
            class="span-2"
            :required="hasBytearkLighthouse"
            :onBlur="
              (e) =>
                hasBytearkLighthouse && validateInput(e.target.value, 'title')
            "
            :isShowErrorMessage="errors.title"
            errorMessage="Please put Title"
          />
          <FormInput
            v-model="playerOptions.sources.videoId"
            title="Video Key"
            helpText="ไอดีของวิดีโอในระบบ ByteArk"
            guideText="*Required เมื่อเปิดใช้งาน ByteArk Lighthouse"
            placeholder="Video key"
            class="span-2"
            :required="hasBytearkLighthouse"
            :onBlur="
              (e) =>
                hasBytearkLighthouse
                  ? validateInput(e.target.value, 'videoId')
                  : ''
            "
            :isShowErrorMessage="errors.videoId"
            errorMessage="Please put Video Key"
          />
          <FormInput
            v-model="playerOptions.sources.poster"
            title="Poster URL"
            helpText="A URL to an image that displays before the video begins playing."
            placeholder="Poster URL"
            class="span-2-end"
          />
          <FormInput
            v-model="playerOptions.sources.subtitle"
            title="Excerpt"
            helpText="ชื่อตอน หรือรายละเอียดสั้นๆของวิดีโอ"
            placeholder="Excerpt"
            class="span-4"
          />
        </div>
      </SectionItem>
      <SectionItem title="Options Settings" id="optionsSettings">
        <Collapse title="General Options" isExpand>
          <template #content>
            <div class="general-options-container">
              <div class="left-container">
                <FormSelect
                  v-model="playerOptions.autoplay"
                  title="Autoplay"
                  helpText="เริ่มเล่นวิดีโออัตโนมัติ สามารถดูการตั้งค่าได้จากเอกสารของ Video.js"
                  placeholder="Select an autoplay value"
                  :data="['true', 'false', 'muted', 'auto']"
                >
                  <template #help-text>
                    <img
                      class="hyper-link"
                      :src="require('@/assets/images/hyperlink.png')"
                      alt=""
                    />
                  </template>
                </FormSelect>
                <FormVolumeSlider
                  title="Volume"
                  helpText="ระดับเสียงของวิดีโอ"
                  v-model="playerOptions.volume"
                />
                <FormMultiCheckBox
                  title="Playback Speed"
                  helpText="ระดับความเร็วในการเล่นวิดีโอที่เปิดให้ผู้ใช้ควบคุม"
                  guideText="(หน่วยเป็นเท่า)"
                >
                  <FormCheckBox
                    title="0.5"
                    :onChange="
                      (isChecked) => onCheckedPlaybackRate(isChecked, '0.5')
                    "
                    :modelValue="playerOptions.playbackRates?.includes('0.5')"
                    isSubItem
                  />
                  <FormCheckBox
                    title="1"
                    :onChange="
                      (isChecked) => onCheckedPlaybackRate(isChecked, '1')
                    "
                    :modelValue="playerOptions.playbackRates?.includes('1')"
                    isSubItem
                  />
                  <FormCheckBox
                    title="1.5"
                    :onChange="
                      (isChecked) => onCheckedPlaybackRate(isChecked, '1.5')
                    "
                    :modelValue="playerOptions.playbackRates?.includes('1.5')"
                    isSubItem
                  />
                  <FormCheckBox
                    title="2"
                    :onChange="
                      (isChecked) => onCheckedPlaybackRate(isChecked, '2')
                    "
                    :modelValue="playerOptions.playbackRates?.includes('2')"
                    isSubItem
                  />
                  <FormCheckBox
                    title="All"
                    :onChange="
                      (isChecked) => onCheckedAllPlaybackRates(isChecked)
                    "
                    :modelValue="playerOptions.playbackRates?.length === 4"
                    isSubItem
                  />
                </FormMultiCheckBox>
              </div>
              <div class="right-container">
                <FormCheckBox
                  title="Plays In Line"
                  helpText="แสดงผลวิดีโอแบบ inline"
                  guideText="(Default: true)"
                  v-model="playerOptions.playsinline"
                />
                <FormCheckBox
                  title="Auto Rotate Screen"
                  helpText="Rotate the player according to the device's orientation."
                  v-model="playerOptions.autoRotate"
                />
                <FormCheckBox
                  title="Muted"
                  helpText="Mute the video on start"
                  v-model="playerOptions.muted"
                />
                <FormCheckBox
                  title="Loop"
                  helpText="เริ่มเล่นวิดีโอใหม่เมื่อเล่นจบ"
                  v-model="playerOptions.loop"
                />
                <FormCheckBox
                  title="Fluid"
                  helpText="Video player จะขยายขนาดจนเต็ม container"
                  v-model="hasFluid"
                >
                  <FormSelect
                    v-show="hasFluid"
                    class="sub-item"
                    v-model="playerOptions.aspectRatio"
                    title="Aspect Ratio"
                    helpText="กำหนดอัตราส่วนภาพของวิดีโอ (16:9)"
                    placeholder="Select an aspect ratio"
                    :data="['16:9', '4:3']"
                    isSubItem
                  />
                </FormCheckBox>
              </div>
            </div>
          </template>
        </Collapse>
        <Collapse
          title="Control Bar"
          isExpand
          hasToggleBtn
          v-model="hasControlBar"
        >
          <template #help-text>
            <div class="collapse-help-text">
              แสดงหรือซ่อนแถบควบคุม
              <span class="guide-text"> (Default: true) </span>
            </div>
          </template>
          <template #content>
            <div class="flex-col-options-container">
              <FormCheckBox
                title="Live UI"
                helpText="เปิด/ปิด seekbar เมื่อเล่นวิดีโอ Live Stream 
                แบบ DVR"
                v-model="playerOptions.liveui"
              />
              <FormCheckBox
                title="Seek Buttons"
                helpText="แสดง/ซ่อนปุ่ม Seek Buttons"
                v-model="playerOptions.seekButtons"
              />
              <FormCheckBox
                title="Skip Intro Buttons"
                helpText="แสดง/ซ่อนปุ่ม Skip Intro"
                v-model="hasSkipIntroButton"
              >
                <FormInput
                  v-show="hasSkipIntroButton"
                  v-model="playerOptions.skipIntroButton.time"
                  class="sub-item w-40"
                  placeholder="0"
                  title="Time (seconds)"
                  helpText="เวลาที่ต้องการให้ข้ามไปเล่นเมื่อกดปุ่ม Skip Intro"
                  :onBlur="(e) => validateInput(e.target.value, 'skipTime')"
                  :isShowErrorMessage="errors.skipTime"
                  errorMessage="Please put Skip Intro Time"
                  required
                  isSubItem
                />
              </FormCheckBox>
              <FormCheckBox
                title="Share Button"
                helpText="แสดง/ซ่อนปุ่ม Share"
                v-model="hasShareButton"
              >
                <FormInput
                  v-show="hasShareButton"
                  v-model="playerOptions.share.url"
                  class="sub-item"
                  title="URL"
                  helpText="URL ที่ต้องการแชร์"
                  placeholder="Share URL"
                  :onBlur="(e) => validateInput(e.target.value, 'shareUrl')"
                  :isShowErrorMessage="errors.shareUrl"
                  errorMessage="Please put Share URL"
                  required
                  isSubItem
                />
                <FormCodeEditor
                  v-show="hasShareButton"
                  class="sub-item"
                  title="Listener"
                  :defaultCode="shareDefaultCode"
                  helpText="ฟังก์ชันที่จะถูกเรียกเมื่อผู้ใช้เลือกแชร์ไปยังโซเซียลเน็ตเวิร์ค"
                  v-model="playerOptions.share.listener"
                  isSubItem
                />
              </FormCheckBox>
            </div>
          </template>
        </Collapse>
        <Collapse title="Context Menu" isExpand>
          <template #help-text>
            <div class="collapse-help-text">
              รายการเมนูที่จะปรากฏเมื่อผู้ใช้คลิกขวาที่ตัวเล่นวิดิโอ
              เมนูจะแสดงผลเรียงตามลำดับที่ท่านสร้าง
            </div>
          </template>
          <template #content>
            <FormAddMenu
              title="Add context menu"
              v-model="playerOptions.menu"
            />
          </template>
        </Collapse>
        <Collapse title="การแสดงผล Error" isExpand>
          <template #help-text>
            <div class="collapse-help-text">
              ByteArk Player รองรับการแสดงผล error เป็นรูปภาพหรือข้อความ เมื่อพบ
              error ที่มาจาก HTTP Status Code
            </div>
          </template>
          <template #content>
            <div class="flex-col-options-container">
              <FormToggleInput
                v-model="playerOptions.errors[403]"
                v-model:isToggled="hasErr403"
                title="403 - Invalid Signed URL"
                :onBlur="(e) => validateInput"
                errorMessage="required"
                filedId="403"
              />
              <FormToggleInput
                v-model="playerOptions.errors[404]"
                v-model:isToggled="hasErr404"
                title="404 - Video Not Found"
                :onBlur="(e) => validateInput"
                errorMessage="required"
                filedId="404"
              />
              <FormToggleInput
                v-model="playerOptions.errors[410]"
                v-model:isToggled="hasErr410"
                title="410 - Expired Signed URL"
                :onBlur="(e) => validateInput"
                errorMessage="required"
                filedId="410"
              />
              <FormToggleInput
                v-model="playerOptions.errors[451]"
                v-model:isToggled="hasErr451"
                title="451 - This video is not available in your country"
                :onBlur="(e) => validateInput"
                :isShowErrorMessage="errors.err451"
                errorMessage="required"
                filedId="451"
              />
            </div>
          </template>
        </Collapse>
        <Collapse title="Advertisements" hasToggleBtn v-model="hasBytearkAds">
          <template #help-text>
            <div class="collapse-help-text">
              ByteArk Player รองรับการแสดงผลโฆษณาโดยใช้ Google Interactive Media
              Ads SDK (Google IMA SDK for HTML5)
            </div>
          </template>
          <template #content>
            <div class="flex-col-options-container">
              <FormInput
                v-model="playerOptions.plugins.bytearkAds.adTagUrl"
                title="Ad Tag URL"
                helpText="URL ของ VAST, VMAP หรือ VPAID"
                placeholder="Advertisement URL"
                :disabled="!hasBytearkAds"
              />
              <FormCheckBox
                v-model="playerOptions.autoplayadsmuted"
                title="Autoplay Ads & Muted"
                helpText="เริ่มเล่นวิดิโออัตโนมัติและปิดเสียง เมื่อมีโฆษณา preroll"
                guideText="(Default: false)"
                :disabled="!hasBytearkAds"
              />
            </div>
          </template>
        </Collapse>
        <Collapse
          title="ByteArk Lighthouse"
          hasToggleBtn
          v-model="hasBytearkLighthouse"
        >
          <template #help-text>
            <div class="collapse-help-text">
              เก็บข้อมูลสถิติการรับชมวิดีโอ (VOD) จากฝั่ง Client-side
              เพื่อให้ได้ข้อมูลสถิติ และ พฤติกรรมการดูวิดีโอที่ละเอียดขึ้น
            </div>
          </template>
          <template #content>
            <div class="flex-col-options-container">
              <FormInput
                v-model="playerOptions.plugins.bytearkLighthouse.projectId"
                title="Project ID"
                helpText="Project ID ที่ได้จากการสร้างในเว็ปไซต์ ByteArk Lighthouse"
                placeholder="ByteArk Lighthouse Project ID"
                :disabled="!hasBytearkLighthouse"
                :onBlur="(e) => validateInput(e.target.value, 'projectId')"
                :isShowErrorMessage="errors.projectId"
                errorMessage="Please put Project ID"
                required
              />
              <div class="stuck-container">
                <p class="title">Stuck Checking</p>
                <div class="stuck-item">
                  <FormInput
                    v-model="
                      playerOptions.plugins.bytearkLighthouse
                        .stuckCheckingTimeInterval
                    "
                    title="Checking Time (seconds)"
                    helpText="ช่วงเวลาสำหรับการเช็คสถานะกำลังโหลดของ player"
                    :disabled="!hasBytearkLighthouse"
                    isSubItem
                  />
                  <FormSelect
                    v-model="
                      playerOptions.plugins.bytearkLighthouse.stuckCheckingUi
                    "
                    title="Show Playback ID Label while Checking"
                    helpText="เปิด/ปิด Playback id label ที่จะขึ้นในขณะที่ player ติดอยู่ในสถานะกำลังโหลดนานเกินกว่าช่วงเวลาที่กำหนด"
                    :disabled="!hasBytearkLighthouse"
                    isSubItem
                    :data="['on', 'off', 'auto']"
                  />
                </div>
              </div>
              <FormCheckBox
                v-model="playerOptions.plugins.bytearkLighthouse.debug"
                title="Enable Debug"
                helpText="เปิด/ปิด debug ByteArk Lighthouse"
                :disabled="!hasBytearkLighthouse"
              />
            </div>
          </template>
        </Collapse>
        <Collapse
          title="ByteArk Firework"
          hasToggleBtn
          v-model="hasBytearkFirework"
        >
          <template #help-text>
            <div class="collapse-help-text">
              ส่งโฆษณาถึงผู้ที่กำลังรับชมการถ่ายทอดสดอยู่ ผ่านโปรโตคอล Digital
              Video Ad Serving Template (VAST)
            </div>
          </template>
          <template #content>
            <div class="flex-col-options-container">
              <FormInput
                v-model="playerOptions.plugins.bytearkFirework.subscriberUrl"
                title="Subscriber URL"
                helpText="Subscriber URL ที่ได้จากการเพิ่มแชนแนลใน ByteArk Firework"
                placeholder="Subscriber URL"
                :disabled="!hasBytearkFirework"
                :onBlur="(e) => validateInput(e.target.value, 'subscriberUrl')"
                :isShowErrorMessage="errors.subscriberUrl"
                errorMessage="Please put Subscriber URL"
                required
              />
              <FormCheckBox
                v-model="playerOptions.plugins.bytearkFirework.debug"
                title="Enable Debug"
                helpText="เปิด/ปิด debug ByteArk Firework"
                :disabled="!hasBytearkFirework"
              />
              <FormCheckBox
                v-model="playerOptions.plugins.bytearkFirework.allowAdReplace"
                title="Allow Ad Replace"
                helpText="ยอมให้เล่นโฆษณาตัวใหม่ ในขณะที่ตัวปัจจุบันกำลังเล่นอยู่"
                :disabled="!hasBytearkFirework"
              />
            </div>
          </template>
        </Collapse>
      </SectionItem>
      <div
        class="form-footer"
        :class="isBtnGroupStick ? 'stick-btn-group' : ''"
      >
        <a type="button" @click="onResetForm" class="reset-btn"> Reset </a>
        <button
          type="submit"
          :disabled="Object.values(errors).filter((ele) => ele).length !== 0"
        >
          <svg
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.5 1.73665C6.27674 1.74013 6.05514 1.77542 5.84194 1.84147C5.94056 2.01407 5.99306 2.20894 5.99445 2.40749C5.99445 2.56165 5.96394 2.71431 5.90465 2.85674C5.84537 2.99917 5.75848 3.12859 5.64894 3.2376C5.5394 3.34661 5.40936 3.43309 5.26624 3.49208C5.12312 3.55108 4.96973 3.58144 4.81482 3.58144C4.61532 3.58007 4.4195 3.52781 4.24607 3.42967C4.10924 3.90193 4.12519 4.40507 4.29165 4.86782C4.45812 5.33056 4.76667 5.72945 5.17359 6.00799C5.58051 6.28652 6.06518 6.43057 6.55894 6.41975C7.0527 6.40892 7.53052 6.24376 7.92472 5.94766C8.31892 5.65157 8.60951 5.23954 8.75534 4.76995C8.90117 4.30036 8.89483 3.79701 8.73724 3.3312C8.57964 2.86539 8.27877 2.46073 7.87724 2.17455C7.47571 1.88837 6.99388 1.73517 6.5 1.73665ZM12.4934 3.7785C11.351 1.56035 9.0895 0.0595703 6.5 0.0595703C3.91051 0.0595703 1.64836 1.5614 0.506644 3.77871C0.458456 3.87357 0.43335 3.97838 0.43335 4.08467C0.43335 4.19097 0.458456 4.29578 0.506644 4.39064C1.64899 6.60879 3.91051 8.10957 6.5 8.10957C9.0895 8.10957 11.3517 6.60774 12.4934 4.39043C12.5416 4.29557 12.5667 4.19076 12.5667 4.08447C12.5667 3.97817 12.5416 3.87336 12.4934 3.7785ZM6.5 7.10332C4.42196 7.10332 2.51686 5.95033 1.48805 4.08457C2.51686 2.21881 4.42175 1.06582 6.5 1.06582C8.57826 1.06582 10.4832 2.21881 11.512 4.08457C10.4834 5.95033 8.57826 7.10332 6.5 7.10332Z"
              fill="white"
            />
          </svg>
          Preview Player
        </button>
      </div>
    </form>
  </Section>
</template>

<script>
import { defaultOptions } from "../../utils/defaultOptions";
import { optionsTypes } from "../../utils/optionsTypes";
import useFormValidation from "../../utils/useFormValidation";

import Section from "./Section.vue";
import Collapse from "./Collapse.vue";
import SectionItem from "../molecules/SectionItem.vue";
import FormSelect from "../molecules/FormSelect.vue";
import FormInput from "../molecules/FormInput.vue";
import FormVolumeSlider from "../molecules/FormVolumeSlider.vue";
import FormCheckBox from "../molecules/FormCheckBox.vue";
import FormCodeEditor from "../molecules/FormCodeEditor.vue";
import FormMultiCheckBox from "../molecules/FormMultiCheckBox.vue";
import FormToggleInput from "../molecules/FormToggleInput.vue";
import FormAddMenu from "../molecules/FormAddMenu.vue";
export default {
  name: "ConfigForm",
  components: {
    Section,
    SectionItem,
    Collapse,
    FormSelect,
    FormInput,
    FormVolumeSlider,
    FormCheckBox,
    FormCodeEditor,
    FormMultiCheckBox,
    FormToggleInput,
    FormAddMenu,
  },
  props: {
    optionsProps: optionsTypes,
  },
  data() {
    const { validateNameField, errors } = useFormValidation();
    return {
      playerOptions: null,
      hasSkipIntroButton: false,
      hasFluid: false,
      hasShareButton: false,
      hasErrors: false,
      hasBytearkAds: false,
      hasBytearkFirework: false,
      hasBytearkLighthouse: false,
      hasErr403: false,
      hasErr404: false,
      hasErr410: false,
      hasErr451: false,
      hasControlBar: true,
      isBtnGroupStick: false,
      validateNameField: validateNameField,
      isFormCompleted: false,
      errors: errors,
      shareDefaultCode:
        "function(socialType) {\n\tconsole.log('share to', socialType); // Facebook, Twitter, or Line\n}\n",
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    this.playerOptions = {
      ...JSON.parse(JSON.stringify(defaultOptions)),
      ...this.optionsProps,
    };
    this.hasControlBar = this.playerOptions?.controls || true;
    this.hasFluid = this.playerOptions?.aspectRatio || false;
    this.hasSkipIntroButton =
      this.playerOptions?.skipIntroButton?.time || false;
    this.hasShareButton = this.playerOptions?.share?.url || false;
    this.hasErr403 = this.playerOptions?.errors["403"]?.imageUrl || false;
    this.hasErr404 = this.playerOptions?.errors["404"]?.imageUrl || false;
    this.hasErr410 = this.playerOptions?.errors["410"]?.imageUrl || false;
    this.hasErr451 = this.playerOptions?.errors["451"]?.imageUrl || false;
    this.hasBytearkAds =
      this.playerOptions?.plugins?.bytearkAds?.adTagUrl || false;
    this.hasBytearkFirework =
      this.playerOptions?.plugins?.bytearkFirework?.subscriberUrl || false;
    this.hasBytearkLighthouse =
      this.playerOptions?.plugins?.bytearkLighthouse?.projectId || false;
  },
  unmounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  watch: {
    hasSkipIntroButton(state) {
      if (this.optionsProps?.skipIntroButton?.time) {
        this.playerOptions.skipIntroButton.time =
          this.optionsProps.skipIntroButton.time;
        if (!state) {
          this.playerOptions.skipIntroButton.time = 0;
        }
      }
    },
    hasShareButton(state) {
      if (this.optionsProps?.share?.url) {
        this.playerOptions.share.url = this.optionsProps.share.url;
        if (!state) {
          this.playerOptions.share.url = "";
        }
      }
    },
    hasBytearkAds(isToggled) {
      if (!isToggled) {
        this.playerOptions.plugins.bytearkAds = { adTagUrl: "" };
      }
    },
    hasBytearkFirework(isToggled) {
      if (!isToggled) {
        console.log("close");
        this.playerOptions.plugins.bytearkFirework = {
          subscriberUrl: "",
          debug: null,
          allowAdReplace: null,
        };
        this.errors.subscriberUrl = false;
      }
    },
    hasBytearkLighthouse(isToggled) {
      if (!isToggled) {
        this.playerOptions.plugins.bytearkLighthouse = {
          projectId: "",
          debug: null,
          stuckCheckingUi: "auto",
          stuckCheckingTimeInterval: 3,
        };
        this.playerOptions.sources.videoId = "";
        this.playerOptions.sources.title = "";
        this.errors.projectId = false;
        this.errors.videoId = false;
        this.errors.title = false;
      }
    },
    hasErr403(isToggled) {
      if (!isToggled) {
        this.playerOptions.errors["403"] = {
          imageUrl: "",
          message: "",
        };
      }
    },
    hasErr404(isToggled) {
      if (!isToggled) {
        this.playerOptions.errors["404"] = {
          imageUrl: "",
          message: "",
        };
      }
    },
    hasErr410(isToggled) {
      if (!isToggled) {
        this.playerOptions.errors["410"] = {
          imageUrl: "",
          message: "",
        };
      }
    },
    hasErr451(isToggled) {
      if (!isToggled) {
        this.playerOptions.errors["451"] = {
          imageUrl: "",
          message: "",
        };
      }
    },
  },
  methods: {
    onCheckedPlaybackRate(isChecked, val) {
      if (isChecked) this.playerOptions.playbackRates.push(val);
      else
        this.playerOptions.playbackRates =
          this.playerOptions.playbackRates.filter((ele) => ele !== val);
    },
    onCheckedAllPlaybackRates(isChecked) {
      if (isChecked)
        this.playerOptions.playbackRates = ["0.5", "1", "1.5", "2"];
      else this.playerOptions.playbackRates = [];
    },
    validateInput(val, name) {
      this.validateNameField(name, val);
    },
    onSubmit() {
      if (this.errors.length > 0) {
        this.$router.push({ path: this.$route.path, hash: "#config-form" });
        return;
      }
      let newConfig = {
        ...this.playerOptions,
      };
      this.$emit("newConfig", newConfig);
      console.log("Submitted");
    },
    onScroll() {
      const div = document.querySelector("#configForm");
      const bodyRect = document.body.getBoundingClientRect();
      const offset = Math.round(
        div.getBoundingClientRect().top - bodyRect.top - 60
      );
      const sectionPadding = 32;
      const aboveMargin = 56;
      const btnGroupHeight = 53.5;
      const bottomFormPos =
        div.getBoundingClientRect().height -
        sectionPadding * 2 -
        aboveMargin -
        btnGroupHeight;
      // console.log(
      //   bottomFormPos,
      //   document.documentElement.scrollTop
      // );

      if (
        offset <= document.documentElement.scrollTop &&
        document.documentElement.scrollTop + 37.5 < bottomFormPos
      ) {
        this.isBtnGroupStick = true;
      }
      if (
        bottomFormPos <= document.documentElement.scrollTop ||
        offset > document.documentElement.scrollTop
      ) {
        this.isBtnGroupStick = false;
        return;
      }
    },
    onResetForm() {
      this.playerOptions = { ...defaultOptions };
      this.errors = {};
      this.hasControlBar = true;
      this.hasFluid = false;
      this.hasSkipIntroButton = false;
      this.hasShareButton = false;
      this.hasErr403 = false;
      this.hasErr404 = false;
      this.hasErr410 = false;
      this.hasErr451 = false;
      this.hasBytearkAds = false;
      this.hasBytearkFirework = false;
      this.hasBytearkLighthouse = false;
      this.$router.push({
        path: this.$route.path,
        query: { config: this.$route.query.config },
        hash: "#config-form",
      });
      console.log("reset form");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/button.scss";
@import "~@/assets/scss/container.scss";
@import "~@/assets/scss/checkbox.scss";
@import "~@/assets/scss/variables.scss";
img {
  width: 9.1px;
  height: 9.1px;
}
.container {
  flex-direction: column;
}
form {
  position: relative;
  h2 {
    margin: 0 0 $space * 2 0;
    font-weight: 700;
    font-size: $base-font-size + 10px;
    color: $grey-1000;
  }
  .form-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: $space + 24px;
    font-size: $base-font-size;
    .reset-btn {
      background: none;
      color: $grey-800;
      text-decoration: underline;
      cursor: pointer;
    }
    &.stick-btn-group {
      position: fixed;
      z-index: 2;
      bottom: 0;
      right: 50%;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      padding: $space / 2 $space * 2;
      transform: translateX(50%);
      border-radius: 0 0 $radius $radius;
      background-color: $bg-light;
      box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.05);
    }
  }
}
.sources-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, auto);
  gap: 0 2rem;
  div:last-child {
    margin: 0;
  }
  .span-2 {
    grid-column: 1 / span 2;
  }
  .span-2-end {
    grid-column: 3 / span 2;
  }
  .span-4 {
    grid-column: 1 / span 4;
  }
  .span-1 {
    grid-column: 1 / span 1;
  }
  .span-3-end {
    grid-column: 2 / span 3;
  }
  .form-input {
    margin-bottom: $space;
  }
  @media (max-width: $breakpoint-tablet) {
    display: flex;
    flex-direction: column;
  }
}

.general-options-container {
  display: flex;
  column-gap: $space * 5;
  .left-container {
    display: flex;
    flex-direction: column;
    row-gap: $space * 2;
    flex: 1;
    .hyper-link {
      height: $space - 2px;
      width: $space - 2px;
    }
  }
  .right-container {
    display: flex;
    flex-direction: column;
    row-gap: $space + 8px;
    flex: 1;
  }
  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}
.flex-col-options-container {
  display: flex;
  flex-direction: column;
  row-gap: $space + 8px;
}
.stuck-container {
  .stuck-item {
    display: flex;
    column-gap: $space * 2;
    width: 60%;
  }
}
.w-40 {
  width: 40%;
}
.ml-20 {
  margin-left: $space;
}
</style>
