<template>
  <div class="input-wrapper">
    <div
      class="input-container"
      :class="{
        'has-error': errorMessage && isShowErrorMessage,
        disabled: disabled,
      }"
    >
      <input
        class="input"
        :placeholder="placeholder"
        :class="{ 'has-icon': $slots['icon'] }"
        :disabled="disabled"
        :value="modelValue"
        @blur="onBlur"
        @change="onChange"
        @input="(event) => $emit('update:modelValue', event.target.value)"
      />
      <div class="icon-container" v-if="$slots['icon']">
        <slot name="icon"></slot>
      </div>
    </div>
    <div class="error-text" v-if="errorMessage && isShowErrorMessage">
      <i class="fa fa-warning" />
      {{ errorMessage }}
    </div>
    <div
      class="help-text"
      v-if="helpText && !(errorMessage && isShowErrorMessage)"
    >
      <slot name="help-text"></slot>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["update:modelValue"],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: "",
      type: String,
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    modelValue: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.input-wrapper {
  position: relative;
}
.input-container {
  margin: 0 0 $space/4 0;
  width: 100%;
  position: relative;
  border: 1px solid $grey-400;
  background: white;
  border-radius: $radius;
  transition: all 0.2s ease-in-out;
  &.disabled {
    background: $grey-400;
  }
  &.has-error {
    border: 1px solid $danger;
  }
  &:not(.disabled),
  &:not(.has-error) {
    &:focus-within {
      border: 1px solid rgba(45, 131, 219, 0.5);
    }
  }
}
.input {
  color: $grey-1000;
  outline: none;
  padding: 8px 10px;
  border-radius: $radius;
  width: 100%;
  border: 0;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey-500;
    opacity: 1; /* Firefox */
  }
  &.has-icon {
    width: calc(100% - $space * 2);
  }
}
.icon-container {
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: $grey-700;
}
.error-text {
  display: flex;
  align-items: center;
  color: $danger;
  font-size: $base-font-size - 2px;
  i {
    font-size: $base-font-size - 4px;
    margin-right: 4px;
  }
}
.help-text {
  font-size: $base-font-size - 2px;
  color: $grey-700;
  font-weight: 400;
}
</style>
