<template>
  <label class="toggle-container" tabindex="0" role="checkbox">
    <template v-if="labels">
      <span class="toggle-label" v-if="toggled">
        {{ labels.on }}
      </span>
      <span class="toggle-label" v-else>
        {{ labels.off }}
      </span>
    </template>
    <input
      type="checkbox"
      class="toggle-input"
      :name="name"
      :checked="modelValue"
      :disabled="disabled"
      tabindex="-1"
      @change.stop="toggle"
    />
    <div
      class="toggle-ui"
      :class="{
        toggled: toggled,
        disabled: disabled,
        'with-labels': labels,
        'is-small': isSmall,
      }"
    >
      <div class="toggle-button" :class="{ 'is-small': isSmall }"></div>
    </div>
  </label>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: () => "toggle",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    labels: {
      type: Object,
      required: false,
      default: undefined,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue(value) {
      this.toggled = !!value;
    },
  },
  data() {
    return {
      toggled: false,
    };
  },
  created() {
    this.toggled = !!this.modelValue;
  },
  methods: {
    toggle() {
      const toggled = !this.toggled;
      this.toggled = toggled;
      this.$emit("update:modelValue", toggled);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.toggle-container {
  display: flex;
  align-items: center;
  column-gap: $space - 6px;
  position: relative;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  .toggle-input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }
  .toggle-label {
    color: $grey-1000;
    font-weight: 400;
    font-size: $base-font-size;
  }
  .toggle-ui {
    display: block;
    position: relative;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    user-select: none;
    width: 46px;
    height: 24px;
    background-color: #9ea4a9;
    border-radius: 20px;
    transition: background-color 300ms;
    &.is-small {
      width: 35px;
      height: 20px;
    }
    .toggle-button {
      display: block;
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      border-radius: 100%;
      z-index: 2;
      margin: 3px 4px;
      transform: translateX(0);
      width: 18px;
      height: 18px;
      background-color: $white;
      transition: transform 300ms;
      &.is-small {
        width: 16px;
        height: 16px;
        margin: 2px 2px;
      }
    }
    &.toggled {
      background-color: $ci-primary;
      .toggle-button {
        transform: translateX(20px);
        &.is-small {
          transform: translateX(15px);
        }
      }
    }
    &.with-labels {
      width: 50px;
      &.toggled {
        .toggle-button {
          transform: translateX(24px);
        }
      }
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}
</style>
