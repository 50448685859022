<template>
  <div class="form-volume">
    <div class="text-container">
      <div class="title">{{ title }} <span v-show="required">*</span></div>
      <div class="percentage">{{ modelValue*100 }}%</div>
      <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
    </div>
    <Slider
      :modelValue="modelValue*100"
      @update:modelValue="(value) => $emit('update:modelValue', value/100)"
    />
    <div class="help-text">{{ helpText }}</div>
  </div>
</template>

<script>
import Slider from "@/components/atom/Slider.vue";
export default {
  name: "VolumeSlider",
  components: {
    Slider,
  },
  emits: ["update:modelValue"],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: "",
      type: String,
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    modelValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.text-container {
  margin-bottom: $space - 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    color: $grey-1000;
    font-size: $base-font-size;
    font-weight: 700;
  }
  .subtitle {
    font-size: $base-font-size - 2px;
    font-weight: 400;
    color: $grey-700;
  }
  span {
    color: $danger;
  }
}
.help-text {
  font-size: $base-font-size - 2px;
  color: $grey-700;
}
</style>
