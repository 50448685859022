<template>
  <section>
    <h2>404</h2>
    <h3>Page not found</h3>
    <router-link :to="{ name: 'Home' }">
      <button>Back To Home</button>
    </router-link>
  </section>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/button.scss';
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  h2 {
    font-size: 10rem;
    font-weight: 700;
    margin: 0 0 3rem 0;
    letter-spacing: 1rem;
  }
  h3 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0 0 2rem 0;
  }
}
</style>
