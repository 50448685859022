<template>
  <div class="section container" >
    <h2>{{ title }}</h2>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Section",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/container.scss";
.section {
  margin: 0 0 $space * 3.5 0;
}
h2 {
  margin: 0 0 $space * 2 0;
  font-weight: 700;
  font-size: $base-font-size + 10px;
  color: $grey-1000;
}
</style>
