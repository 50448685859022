<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-scroll">
          <div class="modal-header">
            <slot name="header"> default header </slot>
            <svg
              @click="$emit('close')"
              class="close-icon"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8905 16.4264L25.0292 10.2878C25.1193 10.1989 25.1909 10.0931 25.2397 9.97634C25.2886 9.8596 25.3138 9.73432 25.3138 9.60777C25.3138 9.48121 25.2886 9.35593 25.2397 9.23919C25.1909 9.12245 25.1193 9.01659 25.0292 8.92777L23.6585 7.56243C23.5701 7.47245 23.4647 7.40098 23.3484 7.35219C23.2321 7.3034 23.1073 7.27827 22.9812 7.27827C22.8551 7.27827 22.7302 7.3034 22.6139 7.35219C22.4976 7.40098 22.3922 7.47245 22.3038 7.56243L16.1598 13.7011L10.0212 7.56243C9.93234 7.47229 9.82648 7.40071 9.70974 7.35185C9.593 7.30299 9.46772 7.27783 9.34116 7.27783C9.21461 7.27783 9.08933 7.30299 8.97259 7.35185C8.85585 7.40071 8.74999 7.47229 8.66116 7.56243L7.29583 8.92777C7.20569 9.01659 7.13411 9.12245 7.08525 9.23919C7.03639 9.35593 7.01123 9.48121 7.01123 9.60777C7.01123 9.73432 7.03639 9.8596 7.08525 9.97634C7.13411 10.0931 7.20569 10.1989 7.29583 10.2878L13.4345 16.4264L7.29583 22.5704C7.20585 22.6588 7.13438 22.7642 7.08559 22.8805C7.0368 22.9968 7.01167 23.1216 7.01167 23.2478C7.01167 23.3739 7.0368 23.4987 7.08559 23.615C7.13438 23.7313 7.20585 23.8367 7.29583 23.9251L8.66116 25.2958C8.74999 25.3859 8.85585 25.4575 8.97259 25.5063C9.08933 25.5552 9.21461 25.5804 9.34116 25.5804C9.46772 25.5804 9.593 25.5552 9.70974 25.5063C9.82648 25.4575 9.93234 25.3859 10.0212 25.2958L16.1598 19.1571L22.3038 25.2958C22.3922 25.3857 22.4976 25.4572 22.6139 25.506C22.7302 25.5548 22.8551 25.5799 22.9812 25.5799C23.1073 25.5799 23.2321 25.5548 23.3484 25.506C23.4647 25.4572 23.5701 25.3857 23.6585 25.2958L25.0292 23.9251C25.1191 23.8367 25.1906 23.7313 25.2394 23.615C25.2882 23.4987 25.3133 23.3739 25.3133 23.2478C25.3133 23.1216 25.2882 22.9968 25.2394 22.8805C25.1906 22.7642 25.1191 22.6588 25.0292 22.5704L18.8905 16.4264Z"
                fill="#B1B9C0"
              />
            </svg>
          </div>
  
          <div class="modal-body">
            <slot name="body"> default body </slot>
          </div>
        </div>

        <div class="modal-footer" v-show="hasButtonGroup">
          <a type="button" @click="$emit('close')" class="close-btn"> Close </a>
          <button
            :disabled="!isGenerateURLBtnDisabled"
            type="button"
            @click="onGenerateURL"
          >
            Generate Playground URL
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasButtonGroup: {
      type: Boolean,
      default: true,
    },
    isGenerateURLBtnDisabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onGenerateURL() {
      this.$router.push({
        path: this.$route.path,
        query: { config: this.$route.query.config },
        hash: "#player-preview",
      });
      this.$emit("close");
      this.$emit("generateURL");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/button.scss";
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display:flex;
  flex-direction: column;
}
.modal-container {
  width: 100%;
  height: 100%;
  background-color: $bg-light;
  border-radius: $radius + 2px $radius + 2px 0 0;
  padding-top: $space * 3;
  position: relative;
  display: flex;
  flex-direction: column;
}
.modal-scroll{
  overflow-y: scroll;
  height: calc(100vh - 70.5px - $space*3); // 70.5px bottom height , 48-px: top padding
}
.modal-header {
  max-width: 720px;
  margin: 0 auto;
  margin-bottom: $space * 2;
  width: 100%;
  color: $grey-1000;
  font-weight: 700;
  font-size: $base-font-size + 10px;
  .close-icon {
    position: absolute;
    top: $space + 14px;
    right: $space + 14px;
    cursor: pointer;
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  row-gap: $space * 2;
  justify-items: center;
  align-items: center;
  flex: 1;
  margin-bottom: $space * 3;
}

.modal-footer {
  justify-self: flex-end;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: $space + 24px;
  border-top: 1px solid $grey-400;
  padding: $space $space + 8px;
  width: 100%;
  z-index: 998;
  background-color: $grey-100;
  font-size: $base-font-size;
  .close-btn {
    background: none;
    color: $grey-800;
    text-decoration: underline;
    cursor: pointer;
  }
  button {
    padding: $space / 2 $space + 29.5px;
  }
}
</style>
