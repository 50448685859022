<template>
  <div class="code-block">
    <button class="copy-btn" @click="copy(code, language)">
      <div v-if="!isCopied" class="copy-btn-content">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_197_2222)">
            <path
              d="M11.3604 1.6333H4.65205C4.09631 1.6333 3.6458 2.08381 3.6458 2.63955V3.6458H2.63955C2.08381 3.6458 1.6333 4.09631 1.6333 4.65205V11.3604C1.6333 11.9161 2.08381 12.3666 2.63955 12.3666H9.34788C9.90363 12.3666 10.3541 11.9161 10.3541 11.3604V10.3541H11.3604C11.9161 10.3541 12.3666 9.90363 12.3666 9.34788V2.63955C12.3666 2.08381 11.9161 1.6333 11.3604 1.6333ZM9.2221 11.3604H2.76533C2.73197 11.3604 2.69998 11.3471 2.67639 11.3235C2.6528 11.3 2.63955 11.268 2.63955 11.2346V4.77783C2.63955 4.74447 2.6528 4.71248 2.67639 4.68889C2.69998 4.6653 2.73197 4.65205 2.76533 4.65205H3.6458V9.34788C3.6458 9.90363 4.09631 10.3541 4.65205 10.3541H9.34788V11.2346C9.34788 11.268 9.33463 11.3 9.31104 11.3235C9.28746 11.3471 9.25546 11.3604 9.2221 11.3604ZM11.2346 9.34788H4.77783C4.74447 9.34788 4.71248 9.33463 4.68889 9.31104C4.6653 9.28746 4.65205 9.25546 4.65205 9.2221V2.76533C4.65205 2.73197 4.6653 2.69998 4.68889 2.67639C4.71248 2.6528 4.74447 2.63955 4.77783 2.63955H11.2346C11.268 2.63955 11.3 2.6528 11.3235 2.67639C11.3471 2.69998 11.3604 2.73197 11.3604 2.76533V9.2221C11.3604 9.25546 11.3471 9.28746 11.3235 9.31104C11.3 9.33463 11.268 9.34788 11.2346 9.34788Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_197_2222">
              <rect
                width="10.7333"
                height="10.7333"
                fill="white"
                transform="translate(1.6333 1.6333)"
              />
            </clipPath>
          </defs>
        </svg>

        <p>Copy</p>
      </div>
      <div v-else class="copy-btn-content">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.84401 11.4332L1.66134 8.25055C1.62296 8.21277 1.59248 8.16774 1.57168 8.11806C1.55088 8.06839 1.54016 8.01507 1.54016 7.96122C1.54016 7.90737 1.55088 7.85405 1.57168 7.80438C1.59248 7.7547 1.62296 7.70967 1.66134 7.67189L2.07201 7.26122C2.10979 7.22284 2.15483 7.19236 2.2045 7.17156C2.25417 7.15075 2.30749 7.14004 2.36134 7.14004C2.4152 7.14004 2.46851 7.15075 2.51818 7.17156C2.56786 7.19236 2.61289 7.22284 2.65067 7.26122L5.04934 9.65989C5.07165 9.68211 5.10185 9.69459 5.13334 9.69459C5.16483 9.69459 5.19504 9.68211 5.21734 9.65989L11.466 3.41122C11.5038 3.37284 11.5488 3.34236 11.5985 3.32156C11.6482 3.30075 11.7015 3.29004 11.7553 3.29004C11.8092 3.29004 11.8625 3.30075 11.9122 3.32156C11.9619 3.34236 12.0069 3.37284 12.0447 3.41122L12.4553 3.82189C12.4937 3.85967 12.5242 3.9047 12.545 3.95438C12.5658 4.00405 12.5765 4.05737 12.5765 4.11122C12.5765 4.16507 12.5658 4.21839 12.545 4.26806C12.5242 4.31774 12.4937 4.36277 12.4553 4.40055L5.42267 11.4332C5.38489 11.4716 5.33986 11.5021 5.29018 11.5229C5.24051 11.5437 5.1872 11.5544 5.13334 11.5544C5.07949 11.5544 5.02617 11.5437 4.9765 11.5229C4.92683 11.5021 4.88179 11.4716 4.84401 11.4332Z"
            fill="white"
          />
        </svg>
        <p>Copied</p>
      </div>
    </button>
    <prism :language="language" id="code-block">
      {{ decodeHTMLEntities(code) }}
    </prism>
  </div>
</template>

<script>
import Prism from "vue-prism-component";
import "prismjs/components/prism-json";
import "prismjs/components/prism-jsx";
import "prismjs/themes/prism.css";
import he from "he";
export default {
  name: "CodeBlock",
  components: {
    Prism,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    showCopyBtn: Boolean,

  },
  data() {
    return {
      isCopied: false
    }
  },
  methods: {
    copy(value) {
      this.$copyText(value)
        .then(() => {
          console.log("copied!");
        })
        .catch(() => {
          console.log(`can't copy`);
        });

      this.isCopied = true
      setTimeout(() => this.isCopied = false, 3000)
    },
    decodeHTMLEntities(str) {
      if (str && typeof str === "string") {
        str = he.decode(str);
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.code-block {
  position: relative;
  text-align: center;
  flex: 1;
  height: 100%;
  border-radius: $radius;
  #code-block {
    font-size: 0.75rem;
    margin: 0;
    height: 100%;
    border-radius: $radius;
  }
  .copy-btn {
    position: absolute;
    right: $space / 2;
    top: $space / 2;
    border: 0;
    cursor: pointer;
    padding: $space / 4 $space / 2;
    fill: $white;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: $radius;
    color: $white;
    .copy-btn-content{
      display: flex;
      column-gap: 5.63px;
      align-items: center;
    }
  }
}
</style>
