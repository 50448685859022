<template>
  <div
    class="checkbox-ui-container"
    :class="{ checked: modelValue, disabled: disabled }"
    @click="onUpdateValue"
  >
    <input
      type="checkbox"
      class="input"
      :disabled="disabled"
      :checked="modelValue"
      :value="value"
    />
    <font-awesome-icon icon="fa-solid fa-check" class="checkmark" />
  </div>
</template>
<script>
export default {
  emits: ["update:modelValue"],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onUpdateValue() {
      if (this.value != "") {
        this.$emit("update:modelValue", this.value);
      } else {
        this.$emit("update:modelValue", !this.modelValue);
        this.onChange(!this.modelValue);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.checkbox-ui-container {
  border: 2px solid $grey-500;
  border-radius: 2px;
  background: $white;
  width: 1rem;
  height: 1rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  &.checked {
    border-color: transparent;
    background-color: $ci-primary;
    .checkmark {
      display: block;
    }
  }
  &.disabled {
    background: #c5cbd1;
    pointer-events: none;
    .checkmark {
      color: #b1b9c0;
    }
  }
}
/* Hide the browser's default checkbox */
.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 2px + 0.5px;
  right: 0;
  bottom: 0;
  transform: translate(-2px, -2px);
  /* padding: 2px; */
  display: none;
  font-size: $base-font-size - 2px;
  color: $white;
}
</style>
