<template>
  <div class="collapse-container">
    <div class="collapse-header" @click="isToggle = !isToggle">
      <font-awesome-icon
        :icon="`fa-solid fa-angle-${isCollapse ? 'down' : 'right'} fa-3x`"
      />
      <div class="title-container" :class="isCollapse ? 'toggle' : ''">
        <div class="text-container">
          <h3>{{ title }}</h3>
          <p class="subtitle">{{ subtitle }} <slot name="help-text"></slot></p>
        </div>
        <Toggle
          v-model="theModel"
          v-if="hasToggleBtn"
          :labels="{ on: 'Enabled', off: 'Disabled' }"
        />
      </div>
    </div>
    <div v-show="isCollapse" class="collapse-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import Toggle from "../atom/Toggle.vue";
export default {
  setup(props, { emit }) {
    const theModel = computed({
      // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    return { theModel };
  },
  name: "Collapse",
  emits: ["update:modelValue"],
  components: {
    Toggle,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    hasToggleBtn: {
      type: Boolean,
      default: false,
    },
    isExpand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isToggle: false,
      isDisabled: true,
      isCollapse: false,
    };
  },
  created() {
    if (this.isExpand) this.isCollapse = true;
  },
  watch: {
    isToggle(isToggled) {
      this.isCollapse = isToggled;
    },
    theModel(isToggled) {
      if (isToggled) {
        console.log("toggle btn");
        this.isCollapse = true;
        this.isToggle = true;
      } else {
        this.isCollapse = false;
        this.isToggle = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 $space * 3.5 0;
}
.collapse-header {
  display: flex;
  align-items: center;
  column-gap: 11.72px;
  cursor: pointer;
  margin: 0 0 $space 0;
  svg {
    width: 1rem;
    height: 1rem;
    transform: translateY(0.2rem);
    align-self: flex-start;
    color: $grey-1000;
  }
  .title-container {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 0 $space 0;
    border-bottom: 1px solid $bg-light;
  }
  .text-container {
    flex: 1;
    h3 {
      color: $grey-1000;
      font-size: $base-font-size + 4px;
      text-transform: capitalize;
      margin: 0 0 $space / 4 0;
    }
    .subtitle {
      font-size: $base-font-size - 2px;
      font-weight: 400;
      color: $grey-700;
    }
    .help-text {
      font-size: $base-font-size;
      color: $grey-700;
      font-weight: 400;
    }
  }
}
.toggle {
  border-bottom: 1px solid $grey-400 !important;
}
.collapse-content {
  margin: 0 0 0 $space * 2;
}
.guide-text {
  font-weight: 700;
}
</style>
