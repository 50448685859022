
<template>
  <div class="section">
    <h3>{{ title }}</h3>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SectionItem",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin: 0 0 $space*3.5 0;
}
h3 {
  font-size: $base-font-size + 7px;
  font-weight: 700;
  color: $white;
  padding: $space / 2 $space;
  margin: 0 0 $space + 8px 0;
  background-color: $bg-navy;
  border-radius: 3px;
  grid-column: 1 / span 2;
}
</style>
