<template>
  <Section title="Source Code Preview">
    <ul class="tab-container">
      <template v-for="language in languages" :key="language.id">
        <li
          class="tab"
          :class="{ active: displayLanguage === language.title }"
          @click="onSelectLang(language.title)"
        >
          {{ language.title }}
        </li>
      </template>
    </ul>
    <code-block
      :code="code"
      :key="componentKey"
      :language="displaySyntax"
      showCopyBtn
    >
    </code-block>
  </Section>
</template>

<script>
import CodeBlock from "../atom/CodeBlock.vue";
import objectToString from "../../utils/jsonToString";
import Section from "../organisms/Section.vue";
// import TabList from "../molecules/TabList.vue";

export default {
  name: "CodePreview",
  components: {
    Section,
    CodeBlock,
    // TabList,
  },
  props: ["playerOptions"],
  data() {
    return {
      displayLanguage: "javascript",
      displaySyntax: "html",
      code: this.getFormattedCode("javascript"),
      componentKey: 0,
      languages: [
        {
          id: 1,
          title: "javascript",
          syntax: "html",
        },
        {
          id: 2,
          title: "react",
          syntax: "jsx",
        },
        {
          id: 3,
          title: "vue",
          syntax: "html",
        },
      ],
    };
  },
  methods: {
    getFormattedCode(language) {
      switch (language) {
        case "react":
          this.displayLanguage = "react";
          this.displaySyntax = "jsx";
          return `// Player.jsx
import React from 'react'
import { render } from 'react-dom'
import { ByteArkPlayerContainer } from 'byteark-player-react'

const Player = () => {
  const playerOptions = ${objectToString(this.playerOptions)}
  
  return <ByteArkPlayerContainer {...playerOptions} />
}

export default Player`;
        case "javascript":
          this.displayLanguage = "javascript";
          this.displaySyntax = "html";
          return `<div id="the-video-player"></div>
&lt;script src="//byteark-sdk.cdn.byteark.com/player/v2/byteark-player.min.js"&gt;&lt;/script&gt;
&lt;script&gt;
  var player = bytearkPlayer('the-video-player', ${objectToString(
    this.playerOptions
  )});
&lt;/script&gt;`;
        case "vue":
          this.displayLanguage = "vue";
          this.displaySyntax = "html";
          return `<template>
  <ByteArkPlayerContainer
    :options="playerOptions" />
</template>

&lt;script&gt;
import ByteArkPlayerContainer from '@byteark/byteark-player-vue';

export default {
  components: {
    ByteArkPlayerContainer,
  },
  data() {
    return {
      playerOptions: ${objectToString(this.playerOptions)}
    };
  },
};
&lt;/script&gt;
<style lang="scss" scoped>
@import '~@byteark/byteark-player-vue/dist/@byteark/byteark-player-vue.css';
</style>`;
      }
    },
    onSelectLang(language) {
      this.code = this.getFormattedCode(language);
      this.forceRerender();
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  flex-direction: column;
}
h1 {
  margin: 0 0 0.5rem 0;
}
.tab-container {
  margin-bottom: $space;
  .tab {
    padding-bottom: $space / 2;
    cursor: pointer;
    margin-right: $space * 2;
    font-weight: 500;
    color: $grey-800;
    &.active {
      border-bottom: 2px solid $ci-primary;
      color: $ci-primary;
      cursor: default;
    }
  }
}
</style>
