<template>
  <div :class="isSubItem ? 's-form-input' : 'form-input'">
    <div class="text-container">
      <div :class="isSubItem ? 's-title' : 'title'">
        {{ title }}<span v-show="required">*</span>
      </div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
    <CodeEditor
      class="mb-4px"
      :defaultCode="defaultCode"
      :isShowErrorMessage="isShowErrorMessage"
      :errorMessage="errorMessage"
      :modelValue="modelValue"
      :helpText="helpText"
      :onBlur="onBlur"
      :onChange="onChange"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
    >
      <template #help-text>
        <div class="help-text">
          {{ helpText }}
          <span class="guide-text">{{ guideText }}</span>
        </div>
      </template>
    </CodeEditor>
  </div>
</template>

<script>
import CodeEditor from "../atom/CodeEditor.vue";
export default {
  components: {
    CodeEditor,
  },
  emits: ["update:modelValue"],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    defaultCode: {
      default: "",
      type: String,
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    modelValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    isSubItem: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.text-container {
  margin-bottom: $space/2;
  .s-title {
    color: $grey-1000;
    font-size: $base-font-size;
    font-weight: 400;
  }
  .title {
    color: $grey-1000;
    font-size: $base-font-size;
    font-weight: 700;
  }
  .subtitle {
    font-size: $base-font-size - 2px;
    font-weight: 400;
    color: $grey-700;
  }
  span {
    color: $danger;
  }
}
.mb-4px {
  margin-bottom: $space / 4;
}
.help-text {
  font-size: $base-font-size - 2px;
  color: $grey-700;
}
</style>
