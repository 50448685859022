<template>
  <div>
    <div class="text-container">
      <div class="title">{{ title }}</div>
      <div class="subtitle">
        {{ subtitle }}
      </div>
    </div>
    <div class="multi-checkbox">
      <slot></slot>
    </div>
    <div class="help-text">
      {{ helpText }} <span class="guide-text">{{ guideText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    guideText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-checkbox {
  display: flex;
  column-gap: $space * 2;
  margin-bottom: $space - 2px;
}
.text-container {
  margin: 0 0 $space + 2px 0;
  .title {
    color: $grey-1000;
    font-size: $base-font-size;
    font-weight: 700;
  }
  .subtitle {
    font-size: $base-font-size - 2px;
    font-weight: 400;
    color: $grey-700;
  }
}
.help-text {
  font-size: $base-font-size - 2px;
  color: $grey-700;
}
.guide-text {
  font-weight: 700;
}
</style>
