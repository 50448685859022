<template>
  <div class="form-checkbox">
    <CheckBox
      class="mt-1"
      :disabled="disabled"
      :isShowErrorMessage="isShowErrorMessage"
      :errorMessage="errorMessage"
      :modelValue="modelValue"
      :value="value"
      :onBlur="onBlur"
      :onChange="onChange"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
    />
    <div class="content">
      <div class="text-container">
        <div :class="isSubItem ? 's-title' : 'title'">{{ title }}</div>
        <div class="subtitle">
          {{ subtitle }}
        </div>
        <div class="help-text">
          {{ helpText }} <span class="guide-text">{{ guideText }}</span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import CheckBox from "@/components/atom/CheckBox.vue";
export default {
  components: {
    CheckBox,
  },
  emits: ["update:modelValue"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    guideText: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isSubItem: {
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.form-checkbox {
  display: flex;
  column-gap: 0.5rem;
  .mt-1{
    margin-top: 1px;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: $space / 2;
    .text-container {
      /* margin: 0 0 $space/2 0; */
      flex: 1;
      row-gap: $space / 4;
      .title {
        color: $grey-1000;
        font-size: $base-font-size;
        font-weight: 700;
        /* margin: 0 0 $space/4 0; */
      }
      .s-title {
        color: $grey-1000;
        font-size: $base-font-size;
        font-weight: 400;
      }
      .subtitle {
        font-size: $base-font-size - 2px;
        font-weight: 400;
        color: $grey-700;
      }
      .help-text {
        font-size: $base-font-size - 2px;
        color: $grey-700;
        .guide-text {
          font-weight: 700;
        }
      }
    }
  }
}
</style>
