<template>
  <nav ref="target">
    <div>
      <img
        :src="require('@/assets/images/ByteArkLogo.png')"
        alt="ByteArkLogo"
        class="logo"
      />
      <p>Player Playground</p>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/container.scss";
@import "~@/assets/scss/variables.scss";
nav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space $space * 2;
  background-color: #212d37;
  z-index: 999;
  height: 3.75rem;
  margin: 0 0 $space + 10px 0;

  div {
    display: flex;
    align-items: center;
    column-gap: $space / 2;
    p {
      color: $grey-400;
      font-size: $base-font-size + 4px;
      font-family: $logo-font;
      font-weight: 500;
    }
    .logo {
      height: $space + 7px;
      width: $space + 11px;
    }
  }
}

.router-link-active {
  color: #000000;
  text-decoration: none;
}
</style>
