<template>
  <div class="slider-container">
    <Slider
      class="slider"
      :tooltips="false"
      :modelValue="modelValue"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
    />
  </div>
</template>

<script>
import Slider from "@vueform/slider";
export default {
  emits: ["update:modelValue"],
  components: {
    Slider,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@vueform/slider/themes/default.css";
.slider {
  --slider-connect-bg: #2d83db;

  --slider-height: 10px;
  --slider-handle-width: 24px;
  --slider-handle-height: 24px;
  --slider-handle-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  --slider-handle-shadow-active: 0px 1px 4px rgba(0, 0, 0, 0.16);
  --slider-handle-ring-width: 0;
  margin-bottom: $space - 3px;
}
</style>
