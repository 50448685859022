<template>
  <div class="container">
    <div class="title">Playground URL</div>
    <div class="url-container">
      <div class="copy-icon">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2599 6.04002C12.0453 7.82728 12.0208 10.6927 10.2706 12.4526C10.2673 12.4562 10.2634 12.46 10.2599 12.4636L8.25173 14.4717C6.48058 16.2429 3.59901 16.2427 1.82812 14.4717C0.0569673 12.7009 0.0569673 9.819 1.82812 8.04814L2.93695 6.93931C3.231 6.64526 3.73739 6.8407 3.75257 7.25625C3.77194 7.78583 3.86691 8.31789 4.04214 8.8317C4.10149 9.00568 4.05908 9.19812 3.92909 9.32811L3.53802 9.71919C2.70052 10.5567 2.67425 11.9204 3.5035 12.766C4.34094 13.6201 5.7174 13.6251 6.56126 12.7813L8.56938 10.7735C9.41181 9.93103 9.40828 8.56939 8.56938 7.73049C8.45879 7.6201 8.34738 7.53434 8.26037 7.47442C8.19881 7.43215 8.14798 7.37607 8.11193 7.31067C8.07589 7.24527 8.05563 7.17234 8.05277 7.09772C8.04094 6.78195 8.15282 6.45655 8.40234 6.20703L9.03149 5.57785C9.19648 5.41287 9.45529 5.39261 9.6466 5.52612C9.86569 5.67911 10.0709 5.85107 10.2599 6.04002ZM14.4714 1.82828C12.7005 0.057361 9.81893 0.057122 8.04778 1.82828L6.03966 3.8364C6.03607 3.83999 6.03218 3.84387 6.0289 3.84746C4.27878 5.60731 4.25425 8.47275 6.03966 10.26C6.2286 10.4489 6.4338 10.6209 6.65288 10.7739C6.84419 10.9074 7.10304 10.8871 7.26799 10.7221L7.89714 10.093C8.14666 9.84344 8.25854 9.51805 8.24671 9.20228C8.24385 9.12765 8.22359 9.05473 8.18755 8.98933C8.1515 8.92392 8.10068 8.86785 8.03911 8.82557C7.9521 8.76566 7.84069 8.67989 7.7301 8.56951C6.8912 7.73061 6.88767 6.36897 7.7301 5.52654L9.73822 3.51872C10.5821 2.67486 11.9585 2.67994 12.796 3.53396C13.6252 4.37964 13.599 5.74331 12.7615 6.58081L12.3704 6.97188C12.2404 7.10187 12.198 7.29432 12.2573 7.4683C12.4326 7.9821 12.5275 8.51416 12.5469 9.04375C12.5621 9.4593 13.0685 9.65473 13.3625 9.36068L14.4714 8.25185C16.2425 6.48103 16.2425 3.59913 14.4714 1.82828Z"
            fill="#9EA4A9"
          />
        </svg>
      </div>
      <div class="playground-url">
        {{ playgroundURL }}
      </div>
      <div v-if="!isCopied" class="copy-btn" @click="copy(playgroundURL)">
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_241_6473)">
            <path
              d="M9.86038 0.633789H3.15205C2.59631 0.633789 2.1458 1.0843 2.1458 1.64004V2.64629H1.13955C0.583807 2.64629 0.133301 3.0968 0.133301 3.65254V10.3609C0.133301 10.9166 0.583807 11.3671 1.13955 11.3671H7.84788C8.40363 11.3671 8.85413 10.9166 8.85413 10.3609V9.35462H9.86038C10.4161 9.35462 10.8666 8.90412 10.8666 8.34837V1.64004C10.8666 1.0843 10.4161 0.633789 9.86038 0.633789ZM7.7221 10.3609H1.26533C1.23197 10.3609 1.19998 10.3476 1.17639 10.324C1.1528 10.3004 1.13955 10.2685 1.13955 10.2351V3.77832C1.13955 3.74496 1.1528 3.71297 1.17639 3.68938C1.19998 3.66579 1.23197 3.65254 1.26533 3.65254H2.1458V8.34837C2.1458 8.90412 2.59631 9.35462 3.15205 9.35462H7.84788V10.2351C7.84788 10.2685 7.83463 10.3004 7.81104 10.324C7.78746 10.3476 7.75546 10.3609 7.7221 10.3609ZM9.7346 8.34837H3.27783C3.24447 8.34837 3.21248 8.33512 3.18889 8.31153C3.1653 8.28794 3.15205 8.25595 3.15205 8.22259V1.76582C3.15205 1.73246 3.1653 1.70047 3.18889 1.67688C3.21248 1.65329 3.24447 1.64004 3.27783 1.64004H9.7346C9.76796 1.64004 9.79996 1.65329 9.82354 1.67688C9.84713 1.70047 9.86038 1.73246 9.86038 1.76582V8.22259C9.86038 8.25595 9.84713 8.28794 9.82354 8.31153C9.79996 8.33512 9.76796 8.34837 9.7346 8.34837Z"
              fill="#6C757D"
            />
          </g>
          <defs>
            <clipPath id="clip0_241_6473">
              <rect
                width="10.7333"
                height="10.7333"
                fill="white"
                transform="translate(0.133301 0.633789)"
              />
            </clipPath>
          </defs>
        </svg>
        Copy
      </div>
      <div v-else class="copy-btn">
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_241_6473)">
            <path
              d="M9.86038 0.633789H3.15205C2.59631 0.633789 2.1458 1.0843 2.1458 1.64004V2.64629H1.13955C0.583807 2.64629 0.133301 3.0968 0.133301 3.65254V10.3609C0.133301 10.9166 0.583807 11.3671 1.13955 11.3671H7.84788C8.40363 11.3671 8.85413 10.9166 8.85413 10.3609V9.35462H9.86038C10.4161 9.35462 10.8666 8.90412 10.8666 8.34837V1.64004C10.8666 1.0843 10.4161 0.633789 9.86038 0.633789ZM7.7221 10.3609H1.26533C1.23197 10.3609 1.19998 10.3476 1.17639 10.324C1.1528 10.3004 1.13955 10.2685 1.13955 10.2351V3.77832C1.13955 3.74496 1.1528 3.71297 1.17639 3.68938C1.19998 3.66579 1.23197 3.65254 1.26533 3.65254H2.1458V8.34837C2.1458 8.90412 2.59631 9.35462 3.15205 9.35462H7.84788V10.2351C7.84788 10.2685 7.83463 10.3004 7.81104 10.324C7.78746 10.3476 7.75546 10.3609 7.7221 10.3609ZM9.7346 8.34837H3.27783C3.24447 8.34837 3.21248 8.33512 3.18889 8.31153C3.1653 8.28794 3.15205 8.25595 3.15205 8.22259V1.76582C3.15205 1.73246 3.1653 1.70047 3.18889 1.67688C3.21248 1.65329 3.24447 1.64004 3.27783 1.64004H9.7346C9.76796 1.64004 9.79996 1.65329 9.82354 1.67688C9.84713 1.70047 9.86038 1.73246 9.86038 1.76582V8.22259C9.86038 8.25595 9.84713 8.28794 9.82354 8.31153C9.79996 8.33512 9.76796 8.34837 9.7346 8.34837Z"
              fill="#6C757D"
            />
          </g>
          <defs>
            <clipPath id="clip0_241_6473">
              <rect
                width="10.7333"
                height="10.7333"
                fill="white"
                transform="translate(0.133301 0.633789)"
              />
            </clipPath>
          </defs>
        </svg>
        Copied
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playgroundURL: {
      type: String,
      default: "https://url-to-this-playground",
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    copy(value) {
      this.$copyText(value)
        .then(() => {
          console.log("copied!");
        })
        .catch(() => {
          console.log(`can't copy`);
        });

      this.isCopied = true;
      setTimeout(() => (this.isCopied = false), 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/container.scss";
.container {
  flex-direction: column;
  margin-bottom: $space + 8px;
  background-color: $white;
}
.url-container {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: $radius;
  max-height: $space * 3;
  box-sizing: border-box;
  .copy-icon {
    max-height: $space * 3;
    padding: $space;
  }
  .playground-url {
    box-sizing: border-box;
    padding: $space $space - 4px;
    flex: 1;
    color: $grey-700;
    border-left: 0.5px solid $grey-500;
    max-height: $space * 3;
  }
  .copy-btn {
    max-height: $space * 3;
    padding: $space;
    color: $grey-800;
    font-size: $base-font-size;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5.63px;
  }
}
.title {
  font-size: $base-font-size + 4px;
}
</style>
