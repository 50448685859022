export const optionsTypes = {
  type: Object,
  autoplay: Boolean,
  autoplayadsmuted: Boolean,
  controls: {
    type: Boolean,
    default: true,
  },
  sources: {
    title: String,
    src: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    subtitle: String,
    videoId: String,
  },
  poster: String,
  playbackRates: {
    type: Array,
    default: [],
  },
  autoRotate: Boolean,
  aspectRatio: String,
  skipIntroButton: {
    time: Number,
  },
  share: {
    url: String,
  },
  volume: Number,
  fluid: Boolean,
  loop: Boolean,
  playsinline: Boolean,
  seekButtons: Boolean,
  menu: Array,
  plugins: {
    bytearkAds: {
      adTagUrl: String,
    },
  },
  errors: Object,
} 