<template>
  <div class="input-wrapper">
    <div
      class="input-container"
      :class="{
        'has-error': errorMessage && isShowErrorMessage,
        disabled: disabled,
      }"
    >
      <prism-editor
        class="my-editor"
        v-model="code"
        :highlight="highlighter"
        @blur="onBlur"
        @change="onChange"
        @input="(event) => $emit('update:modelValue', event.target.value)"
      ></prism-editor>
    </div>
    <div class="error-text" v-if="errorMessage && isShowErrorMessage">
      <i class="fa fa-warning" />
      {{ errorMessage }}
    </div>
    <div
      class="help-text"
      v-if="helpText && !(errorMessage && isShowErrorMessage)"
    >
      <slot name="help-text"></slot>
    </div>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
// import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

export default {
  components: {
    PrismEditor,
  },
  emits: ["update:modelValue"],
  created() {
    this.code = this.defaultCode;
  },
  data: () => ({
    code: 'console.log("Hello World")',
  }),
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    modelValue: {
      type: String,
      default: "",
    },
    defaultCode: {
      type: String,
      default: "",
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    helpText: {
      type: String,
      default: "",
    },
    errorMessage: {
      default: "",
      type: String,
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
// required class
.my-editor {
  background: $bg-dark-cream;
  color: $grey-1000;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}

.input-container {
  margin: 0 0 $space/4 0;
  border-radius: $radius;
  transition: all 0.2s ease-in-out;
  &.disabled {
    background: $grey-400;
  }
  &.has-error {
    border: 1px solid $danger;
  }
  &:not(.disabled),
  &:not(.has-error) {
    &:focus-within {
      border: 1px solid rgba(45, 131, 219, 0.5);
    }
  }
}

.error-text {
  display: flex;
  align-items: center;
  color: $danger;
  font-size: $base-font-size - 2px;
  i {
    font-size: $base-font-size - 4px;
    margin-right: 4px;
  }
}
</style>
