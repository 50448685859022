import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // redirect example
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/'
  // },

  // catch all 404
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 53.5,
        behavior: 'smooth',
      }
    }
  }
})

export default router
