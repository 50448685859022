import { createApp } from 'vue'

import App from './App.vue'
import copyText from "@meforma/vue-copy-to-clipboard";
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faTrash, faClipboard, faGear, faCheck, faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faTrash)
library.add(faClipboard)
library.add(faGear)
library.add(faCheck)
library.add(faAngleDown)
library.add(faAngleRight)

createApp(App)
  .use(copyText)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .directive('visible', function(el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  })
  .mount("#app")

