<template>
  <div :class="isSubItem ? 's-form-input': 'form-input'">
    <div class="text-container">
      <div :class="isSubItem ? 's-title' : 'title'">
        {{ title }} <span v-show="required">*</span>
      </div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
    <Input
      :disabled="disabled"
      :placeholder="placeholder"
      :isShowErrorMessage="isShowErrorMessage"
      :errorMessage="errorMessage"
      :helpText="helpText"
      :onBlur="onBlur"
      :onChange="onChange"
      :modelValue="modelValue"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
    >
      <template #help-text>
        <div class="help-text">
          {{ helpText }}
          <span class="guide-text">{{ guideText }}</span>
        </div>
      </template>
    </Input>
  </div>
</template>
<script>
import Input from "@/components/atom/Input.vue";
export default {
  components: {
    Input,
  },
  emits: ["update:modelValue"],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: "",
      type: String,
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    modelValue: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    guideText: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false,
    },
    isSubItem: {
      type: Boolean,
      default: false,
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";
.text-container {
  margin-bottom: $space/2;
  .s-title {
    color: $grey-1000;
    font-size: $base-font-size;
    font-weight: 400;
  }
  .title {
    color: $grey-1000;
    font-size: $base-font-size;
    font-weight: 700;
  }
  .subtitle {
    font-size: $base-font-size - 2px;
    font-weight: 400;
    color: $grey-700;
  }
  span {
    color: $danger;
  }
}
.guide-text {
  font-weight: 700;
}
</style>
