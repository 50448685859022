<template>
  <div class="wrapper">
    <div class="header">
      <div class="title">
        {{ title }}
      </div>
      <Toggle v-model="toggleModel" isSmall />
    </div>
    <div v-show="toggleModel">
      <FormInput
        v-model="theModel.imageUrl"
        title="Image Url"
        helpText="URL ของรูปภาพที่ต้องการให้แสดงผลเมื่อมี error เกิดขึ้น"
        placeholder="Image URL"
        class="mb-8px"
        :onBlur="(e) => validateInput(e.target.value, `error${filedId}Image`)"
        :isShowErrorMessage="errors[`error${filedId}Image`]"
        :errorMessage="`Please put error ${fieldId} image URL`"
        isSubItem
        required
      />
      <FormInput
        v-model="theModel.message"
        title="Message"
        helpText="ข้อความที่ต้อง
    การให้แสดงผลเมื่อมี error เกิดขึ้น"
        placeholder="Error message"
        :onBlur="(e) => validateInput(e.target.value, `error${filedId}Message`)"
        :isShowErrorMessage="errors[`error${filedId}Message`]"
        :errorMessage="`Please put error ${fieldId} message`"
        isSubItem
        required
      />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import FormInput from "./FormInput.vue";
import Toggle from "../atom/Toggle.vue";
import useFormValidation from "../../utils/useFormValidation";
export default {
  setup(props, { emit }) {
    const theModel = computed({
      // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });
    const toggleModel = computed({
      get: () => props.isToggled,
      set: (value) => emit("update:isToggled", value),
    });
    const { validateNameField, errors } = useFormValidation();
    return { theModel, toggleModel, validateNameField, errors };
  },
  components: {
    Toggle,
    FormInput,
  },
  emits: ["update:modelValue", "update:isToggled"],
  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    isToggled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    filedId: {
      type: String,
      default: "",
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // isToggle: false,
    };
  },
  methods: {
    validateInput(val, name) {
      this.validateNameField(name, val);
    },
  },
  watch: {
    toggleModel(isToggled) {
      if (!isToggled) {
        this.errors[`error${this.filedId}Image`] = false
        this.errors[`error${this.filedId}Message`] = false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 $space - 10px 0;
}
.title {
  font-weight: 700;
  font-size: $base-font-size + 2px;
}
.mb-8px {
  margin-bottom: $space / 2;
}
</style>
