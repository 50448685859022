import { reactive } from "@vue/reactivity";
const isEmpty = (fieldName, fieldValue) => {
  return !fieldValue ? true : false;
}
const errors = reactive({});

export default function useFormValidation() {
  const validateNameField = (fieldName, fieldValue) => {
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : false
  }
  return { validateNameField , errors}
}

