<template>
  <div class="select-wrapper">
    <div
      class="select-container"
      :class="{
        'has-error': errorMessage && isShowErrorMessage,
        disabled: disabled,
      }"
    >
      <select
        class="select"
        :class="modelValue === '' ? 'no-select' : ''"
        :disabled="disabled"
        v-model="theModel"
      >
        <option
          :selected="!modelValue ? true : false"
          value=""
          disabled="disabled"
        >
          {{ placeholder }}
        </option>
        <template v-for="(option, i) in data" :key="i">
          <option :value="option">{{ option }}</option>
        </template>
      </select>
      <!-- <v-select :options="data" v-model="theModel" ></v-select> -->
    </div>
    <div class="error-text" v-if="errorMessage && isShowErrorMessage">
      <i class="fa fa-warning" />
      {{ errorMessage }}
    </div>
    <div
      class="help-text"
      v-if="helpText && !(errorMessage && isShowErrorMessage)"
    >
      <slot name="help-text"></slot>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
// import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
export default {
    setup(props, { emit }) {
    const theModel = computed({
      // Use computed to wrap the object
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    return { theModel };
  },
  emits: ["update:modelValue"],
  components: {
    // vSelect
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: "",
      type: String,
    },
    isShowErrorMessage: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    modelValue: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    onBlur: {
      type: Function,
      default: () => {},
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    methods: {
      switchSelect(event) {
        console.log(event.target.value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  position: relative;
}
.select-container {
  margin: 0 0 $space/4 0;
  width: 100%;
  position: relative;
  border: 1px solid $grey-400;
  background: $white;
  border-radius: $radius;
  transition: all 0.2s ease-in-out;
  &.disabled {
    background: $grey-600;
  }
  &.has-error {
    border: 1px solid $danger;
  }
  &:not(.disabled),
  &:not(.has-error) {
    &:focus-within {
      border: 1px solid rgba(45, 131, 219, 0.5);
    }
  }
}
.select {
  outline: none;
  padding: 8px 10px;
  border-radius: $radius;
  width: 100%;
  border: 0;
  color: $grey-1000;
}
option {
  color: $grey-1000;
  font-weight: 500;
  font-size: $base-font-size;
}

.error-text {
  display: flex;
  align-items: center;
  color: $danger;
  font-size: $base-font-size - 2px;
  i {
    font-size: $base-font-size - 4px;
    margin-right: 4px;
  }
}
.help-text {
  font-size: $base-font-size - 2px;
  color: $grey-700;
  font-weight: 400;
}
.no-select {
  color: $grey-500;
}
</style>
