export const defaultOptions = {
  autoplay: "",
  autoplayadsmuted: false,
  muted: null,
  volume: 0,
  aspectRatio: "",
  controls: true,
  fluid: null,
  loop: null,
  playsinline: true,
  liveui: null,
  poster: "",
  sources: {
    title: "Test Video",
    src: "https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8",
    type: "application/x-mpegURL",
    subtitle: "",
    videoId: "",
  },
  plugins: {
    bytearkAds: {
      adTagUrl: "",
    },
    bytearkFirework: {
      subscriberUrl: "",
      debug: null,
      allowAdReplace: null
    },
    bytearkLighthouse: {
      projectId: "",
      debug: null,
      stuckCheckingUi: 'auto',
      stuckCheckingTimeInterval: 3
    }
  },
  errors: {
    403: {
      imageUrl: "",
      message: "",
    },
    404: {
      imageUrl: "",
      message: "",
    },
    410: {
      imageUrl: "",
      message: "",
    },
    451: {
      imageUrl: "",
      message: "",
    },
  },
  menu: [],
  playbackRates: [],
  seekButtons: null,
  share: {
    url: "",
  },
  skipIntroButton: {
    time: "",
  },
  autoRotate: null,
};